/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import BOT1 from "../../assets/profitableTrading/14.png";
import BOT2 from "../../assets/profitableTrading/15.png";
import BOT3 from "../../assets/profitableTrading/16.png";
import BOT4 from "../../assets/profitableTrading/17.png";
import BOT5 from "../../assets/profitableTrading/18.png";
import BOT6 from "../../assets/profitableTrading/19.png";
const ProfitableTrading = () => {
  const data = [
    {
      title: "BOT-S",
      icon: BOT1,
      content: (
        <>
          <p>
            BOT-S lets you keep earning profits even as a sideways market moves
            vertical. BOT-S monitor the market all day to find markets with a
            bigger spread in order to make a profit for investors.
          </p>

          <p>
            User can choose our automatic settings to make fast, sustained
            profits OR customize BOT-S for automatic, efficient and emotionless
            trading on a particular asset to take advantage of the ask and bid
            prices.
          </p>

          <p>
            This refers to buying and selling an asset to take advantage of the
            difference between the bid and the ask price.
          </p>
        </>
      ),
    },
    {
      title: "BOT-X",
      icon: BOT2,
      content: (
        <>
          <p>
            The zero knowledge Smart Bot scans hundreds of coins and pairs,
            analyze signals with set algorithms to identify profitable trade
            opportunities 24/7 to execute trades for profits in all market
            conditions even in the event of unplanned market volatility by
            selling coins and buying them back at a lower rate with the
            lightening speed without involving human emotions automatically.
          </p>
          <p>
            BOT-X takes advantage of market opportunities as soon as they arise.
            This lets you take a step back from constantly monitoring the market
            while acting faster than you can, which can help you take advantage
            of market fluctuations.
          </p>
        </>
      ),
    },
    {
      title: "BOT-K",
      icon: BOT3,
      content: (
        <>
          <p>
            BOT-K scans multiple markets and exchanges at the same time for
            price discrepancies and uses AI and set parameters to analyze price
            difference for a pair across different exchange and rapidly buy from
            the cheaper exchange and sell it on the more expensive one to make a
            profit.
          </p>
          <p>
            BOT-K yield profits, often these profits are minimal and require
            large numbers of trades to turn small gains into larger ones.
          </p>
          <p>
            BOT-K is highly efficient to Identifying the right opportunity to
            buy or sell even under highly volatile environment.
          </p>
        </>
      ),
    },

    {
      title: "BOT-R",
      icon: BOT4,
      content: (
        <>
          <p>
            BOT-R performs following the strategy of investing into a currency
            at preset intervals to reduce the entry price of a position over
            time and mitigate volatility risk for better average price for your
            position and greatly reducing risks from the consequences of
            volatility.
          </p>
          <p>
            Automated Investing Made Easy. Irrespective of cryptocurrency
            prices, this smart bot allocate fixed capital at fixed intervals,
            making market fluctuations and capitalize on buying opportunities
            for potential long-term gains.
          </p>
        </>
      ),
    },

    {
      title: "BOT-A",
      icon: BOT5,
      content: (
        <>
          {" "}
          <p>
            BOT-A uses algorithms to identify opportunities in the market and
            make trades accordingly.
          </p>
          <p>
            BOT-A is programmed to execute orders only when a specified signal
            is received giving more control to you. This can help you move ahead
            of the fluctuations.
          </p>
          <p>
            TradingView is the largest technical analysis platform in the
            trading space. Beginners can take advantage of TradingView’s
            extensive functionality which includes more than 100 indicators for
            the best analysis of asset price behavior in addition to testing
            trading strategies.
          </p>
          <p>
            BOTiFi also allows advanced users to create custom indicators and
            signals.
          </p>
        </>
      ),
    },

    {
      title: "BOT-Fi",
      icon: BOT6,
      content: (
        <>
          <p>
            The most versatile bot that effortlessly adapts to market changes,
            ensuring optimal results for your investments. BOT-Fi intelligent
            algorithms enable it to navigate various market scenarios:
          </p>

          <p>
            In a thriving market, BOT-Fi generates significant profits,
            capitalizing on the upward trends.
          </p>

          <p>
            During sideways movements, BOT-Fi continues to secure profits,
            albeit at a slightly reduced rate, providing stability in uncertain
            times.
          </p>
          <p>
            Even in mildly declining markets, BOT-Fi excels at generating small
            yet consistent profits, mitigating potential losses.
          </p>
          <p>
            In more challenging market conditions, BOT-Fi demonstrates
            resilience by minimizing losses, outperforming the downward trend.
          </p>
        </>
      ),
    },
  ];
  return (
    <section className="choose-area pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title text-center mb-50">
              {/* <span className="sub-title">why Choose us </span> */}
              <h2 className="title">
                SMART SOLUTION FOR EVERY MARKET SCENARIO
                {/* <span>Smart</span> Bots */}
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center choose-active">
          {data.map((item, index) => (
            <div
              key={index}
              className="col-md-6 col-sm-10 mb-4 col-12 col-lg-4"
            >
              <div className="choose-item h-100 !tw-p-4">
                <div className="choose-icon">
                  <img src={item.icon} alt={`image-${index}`} />
                </div>
                <div className="choose-content">
                  <h2 className="title"> {item.title} </h2>
                  <div className="tw-flex !tw-text-[16px] tw-flex-col tw-space-y-4">
                    {item.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProfitableTrading;
