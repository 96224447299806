import React from "react";

const HowToBuy = () => {
  return (
    <section id="how-to-buy" className="tw-bg-[#111112] tw-pb-8">
      <div className="container custom-container-four">
        <div className="section-title section-title-two text-center my-5">
          <h2 className="title  !tw-text-[#04A58A]">How To Buy</h2>
        </div>
        <div className="tw-flex tw-justify-between">
          <div className="tw-border tw-w-[49%]  tw-rounded-lg tw-p-3 sm:tw-p-5">
            <div className="step-que text-white tw-font-medium tw-text-[20px]">
              Buy BOTIFI With ETH/USDT on ERC20 Chain
            </div>
            <div className="tw-flex  tw-ml-2 tw-mt-6 tw-flex-col tw-space-y-2">
              <span>
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Step 1:{" "}
                </span>{" "}
                Connect Metamask/TrustWallet with presale.botifi.ai
              </span>{" "}
              <span>
                {" "}
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Step 2:{" "}
                </span>{" "}
                Enter the amount you wish to purchase & Click on Buy Now
              </span>
              <span>
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Final Step:{" "}
                </span>{" "}
                Approve the transaction in your wallet for spending (use default
                in custom spending cap) click approve, and wait for confirmation
                Now click "Approve" again.
                <br />
                Please ensure you have ETH to cover your gas fees.
              </span>
            </div>
          </div>
          <div className="tw-border tw-w-[49%]  tw-rounded-lg tw-p-3 sm:tw-p-5">
            <div className="step-que text-white tw-font-medium tw-text-[20px]">
              Buy BOTIFI With BNB/USDT or BUSD on BEP20 Chain
            </div>
            <div className="tw-flex  tw-ml-2 tw-mt-6 tw-flex-col tw-space-y-2">
              <span>
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Step 1:{" "}
                </span>{" "}
                Connect Metamask/TrustWallet with presale.botifi.ai
              </span>{" "}
              <span>
                {" "}
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Step 2:{" "}
                </span>{" "}
                Enter the amount you wish to purchase & Click on Buy Now
              </span>
              <span>
                <span className="tw-font-bold tw-text-[18px] leading-[1.2] tw-flex">
                  Final Step:{" "}
                </span>{" "}
                Approve the transaction in your wallet for spending (use default
                in custom spending cap) click approve, and wait for confirmation
                Now click "Approve" again.
                <br />
                Please ensure you have BNB to cover your gas fees.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuy;
