import React from "react";
import SalesHeading from "./SalesHeading";

const TokenVestingTable = () => {
  return (
    <section className="">
      <div className="container">
        <SalesHeading title=" botifi token vesting" />
        <div className="table-container">
          <div>
            <div class="tw-relative tw-overflow-x-auto  tw-mx-auto">
              <table className="mx-auto tw-text-sm tw-text-left  tw-dark:text-gray-400 tw-border-neutral-400 border-2 tw-w-[930px] ">
                <thead class="tw-text-[25px] tw-text-neutral-300  tw-uppercase tw-bg-gray-700 tw-text-center tw-font-mono">
                  <tr>
                    <th
                      tw-scope="col"
                      className="tw-px-6 tw-py-3 border-r-white border-2 w-[300px]"
                    >
                      Allocation
                    </th>
                    <th
                      tw-scope="col"
                      class="tw-px-6 tw-py-3  border-r-white border-2 w-[300px]"
                    >
                      release on TGE
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3  border-r-white border-2 w-[300px]"
                    >
                      vesting
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      presale
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      50% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      30 days cliff,10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      liquidity
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      50% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      team
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      100% locked
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      365 days 10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      marketing
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      100% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      {" "}
                      10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      ecosystem development
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      10% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      180 days locked, 10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      treature
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      100% locked
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      365 days 5% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      platform rewards
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      15% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      {" "}
                      5% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      bug bounty
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      10% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      {" "}
                      10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300  tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      advisors
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      5% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      {" "}
                      10% monthly
                    </td>
                  </tr>
                  <tr class=" tw-border-b tw-uppercase tw-text-neutral-300 tw-text-[20px] tw-text-center">
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      airdrop
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2 w-[300px]">
                      10% unlocks on tge
                    </td>
                    <td class="tw-px-6 tw-py-4  border-r-white border-2  w-[300px]">
                      {" "}
                      10% monthly
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenVestingTable;
