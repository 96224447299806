import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import icon04 from "../../assets/img/icon/choose_icon04.svg";
import MobileUSP from "../../assets/mobileAssets/1.png";
import WhyChooseUsItem from "./WhyChooseUsItem";

import USP from "../../assets/images/banners/Transparent.png";
import { TickIcon } from "../Icons";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "Efficiency & Speed",
      description:
        "AI-powered smart bots analyze market data and execute trades faster than any human, enabling you to take advantage of price fluctuations instantly.",
    },
    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "24/7 Availability",
      description:
        "Our smart bots never sleep. They work round the clock, ensuring you don't miss out on lucrative trading opportunities, even when you're resting.",
    },
    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "Risk Mitigation ",
      description: "Add new, trending and rare artwork to your collection.",
    },
    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "Sercurity & control over money",
      description: `BOTiFi smart bots are designed to minimize risks by employing advanced algorithms to manage your portfolio, including stop-loss and take-profit orders.`,
    },
    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "Data-Driven Decisions",
      description: `BOTiFi smart bots makes decisions based on data and market trends, removing emotional biases that can often lead to impulsive and unprofitable trades.`,
    },

    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: "Diverse Trading Strategies",
      description:
        "BOTiFi smart bots can implement various trading strategies, such as arbitrage, trend following, and market making, depending on your preferences and market conditions.",
    },

    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: `Reduced Human Error`,
      description:
        "Automation eliminates the potential for human error, which can be costly in the volatile crypto market.",
    },

    {
      src: TickIcon,
      alt: "",
      link: "/",
      title: `100% Decentralized Trading`,
      description:
        "Smart fully automated decentralized exchange, where efficiency and automation take center stage without the influence of an organisation or third party. 100% secure & safe trading.",
    },
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section className="choose-area ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">why Choose us </span>
              <h2 className="title">
                Why Switch to <span>BOTiFi</span> Smart Trading
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="slide-progressbar">
          <div
            style={{
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div>

        <div className="tw-w-full tw-cursor-pointer tw-mt-8">
          <img src={USP} alt="" className="tw-w-full md:tw-block tw-hidden" />
          <img
            src={MobileUSP}
            alt=""
            className="tw-w-full tw-block md:tw-hidden"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
