import React from "react";
import img01 from "../../assets/images/team/1.png";
import img02 from "../../assets/images/team/2.png";
import img03 from "../../assets/images/team/3.png";
import img04 from "../../assets/images/team/4.png";
import img05 from "../../assets/images/team/5.png";
import img06 from "../../assets/images/team/6.png";
import img07 from "../../assets/images/team/7.png";
import img08 from "../../assets/images/team/8.png";
import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Bot_X",
      designation: "CEO",
    },
    {
      src: img02,
      name: "Bot_Byte",
      designation: "CTO",
    },
    {
      src: img03,
      name: "Bot_Nova",
      designation: "CSO",
    },
    {
      src: img04,
      name: "Bot_Ninja",
      designation: "CMO",
    },
    {
      src: img05,
      name: "Bot_Nexus",
      designation: "Head of Design",
    },
    {
      src: img06,
      name: "Bot_Titan",
      designation: "Project Manager",
    },
    {
      src: img07,
      name: "Bot_Nano",
      designation: "Head of Operations",
    },
    {
      src: img08,
      name: "Bot_Omega",
      designation: "Lead Advisor",
    },
  ];

  return (
    <section className="team-area py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-5">
              <span className="sub-title">OUr team</span>
              <h2 className="title">
                The Leadership <br /> <span>Team</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
