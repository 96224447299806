/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { GreenTick } from "../Icons";
import GreenTickImg from "../../assets/icons/greenTick.png";
const WhyBotifi = () => {
  return (
    <section id="why-botifi" className=" !tw-pt-0 sm:pt-100 pb-100">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title section-title-two text-center mb-65 ">
              {/* <span className="sub-title">WHY INVESTING</span> */}
              <span className="title !tw-text-[#04A58A]">
                <span className="!tw-text-white">Why investing in</span> BOTIFI
                <sapn className="!tw-text-white">
                  {" "}
                  is a no-brainer decision.
                </sapn>
              </span>
            </div>
          </div>
        </div>

        <div className="tw-mx-auto tw-flex tw-justify-content-center  tw-max-w-[900px]">
          <div className="flex flex-col   mx-auto  space-y-4">
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-1"
                />
              </div>
              <span>Potential for 100x-150x </span>
            </li>
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-2"
                />
              </div>
              <span>Exclusive Access</span>
            </li>
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-3"
                />
              </div>
              <span>High Staking Returns</span>
            </li>
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-4"
                />
              </div>
              <span>Heavy Discounts on Fees</span>
            </li>
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-5"
                />
              </div>
              <span>Governance </span>
            </li>
            <li className="tw-text-[16px] !tw-sm:text-[22px] tw-flex !tw-space-x-2 tw-font-medium tw-border-[#ffffff72] tw-border tw-px-3 tw-rounded-xl tw-py-2 mb-3">
              <div>
                <img
                  src={GreenTickImg}
                  className="!tw-sm:w-[24px] tw-w-[20px] !tw-mt-[5px]"
                  alt="image-6"
                />
              </div>
              <span>
                Receive Platform’s Earnings Share & Referral Earnings.
              </span>
            </li>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyBotifi;
