import React from "react";
import TokenAllocationImage from "../../assets/tokenomics/tokenAllocation.png";

import MobileTokenAllocationImage from "../../assets/mobileAssets/4.png";
import ReactApexChart from "react-apexcharts";
const chartData = {
  series: [
    {
      name: "Series 1",
      data: 44,
    },
    {
      name: "Series 2",
      data: 55,
    },
    {
      name: "Series 2",
      data: 13,
    },
    {
      name: "Series 2",
      data: 33,
    },
  ],

  options: {
    chart: {
      width: "100%",
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 360,
          },
          legend: {
            show: false,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 480,
          },
          legend: {
            show: false,
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 600,
          },
          legend: {
            show: false,
          },
        },
      },
      {
        breakpoint: 1040,
        options: {
          chart: {
            width: 600,
          },
          legend: {
            show: true,
          },
        },
      },

      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 600,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
  },
};
const TokenAllocation = () => {
  return (
    <div className="pie-chart-wrapper !tw-max-w-[800px] mx-auto">
      <img
        src={TokenAllocationImage}
        className="md:tw-block tw-w-full tw-hidden"
        alt="all tokens details"
      />
      <img
        src={MobileTokenAllocationImage}
        className="tw-block tw-w-full md:tw-hidden"
        alt="all tokens details"
      />
      {/* <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
      /> */}
    </div>
  );
};

export default TokenAllocation;
