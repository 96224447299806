import React from "react";

const SalesHeading = ({ title }) => {
  return (
    <h2 className="tw-text-[32px] tw-font-mono tw-uppercase text-center tw-py-3 tw-mb-5 ">
      {title}
    </h2>
  );
};

export default SalesHeading;
