/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>

          <div className="footer-top !tw-py-0">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img
                      className="tw-w-[200px]"
                      src={logoImage}
                      alt="BigTech Logo"
                    />
                  </Link>
                  <div className="footer-content">
                    <p className="tw-leading-[1.1]">
                      First-Ever AI-Powered Smart DEX Offering Fully Automatic
                      Smart Trading 24/7 Under All Market Conditions. Maximise
                      Profits With Emotion-Free Trading Without KYC or Sign Up.
                      100% Secure & Non-Custodial Smart DEX. <br />
                      <span className="tw-text-[#04a58a] leading-[1]">
                        100x Potential Project With Fully Verified Team and
                        Audited Smart Contracts.
                      </span>
                      <li class="header-btn tw-mt-3">
                        <a
                          className="tw-font-bold tw-text-[18px] border-none"
                          href="https://presale.botifi.ai/"
                          target="_blank"
                        >
                          BUY BOTIFI TOKENS
                        </a>
                      </li>
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a
                          href="https://www.youtube.com/@BOTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/BoTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/BoTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.reddit.com/r/Botifi_Ai/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-reddit"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://t.me/BOTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link
                          to="https://bscscan.com/address/0xb1bf223d00a0c0086ee0ae6c8b1fcb1c2e4a479c"
                          target="_blank"
                        >
                          BscScan
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://coinmarketcap.com/dexscan/bsc/0xa997b9d82d2f287302271294329e96f5c7cb2a59/"
                        >
                          CoinMarketCap
                        </Link>
                      </li>
                      <li>
                        <Link to="https://coinmarketcap.com/dexscan/bsc/0xa997b9d82d2f287302271294329e96f5c7cb2a59/#Community">
                          CMC Community
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://github.com/BOTiFiSmartDex"
                          target="_blank"
                        >
                          Github
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://docsend.com/view/2kf4zukhs5fb59bh"
                          target="_blank"
                        >
                          Whitepaper
                        </Link>
                      </li>
                      <li>
                        <Link to="/kyc">KYC Certificate</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link
                          target="_blank"
                          to="https://tawk.to/chat/65074163b1aaa13b7a775ae4/1hai5es2g"
                        >
                          Help Center
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://t.me/BOTiFi_X">
                          B2B Partnerships
                        </Link>
                      </li>
                      <li>
                        <Link to="mailto:connect@botifi.ai">Suggestions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl aliquip
                      ex ea commodo
                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="Info@gmail.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved BOTiFi</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                    {/* <li>
                      <Link to="#">Login / Signup</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
