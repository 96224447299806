import React from "react";

const RoadmapTwoItem = (props) => {
  return (
    <div className="roadmap-item">
      <span className="roadmap-title">{props.item.roadmapTitle}</span>
      <div className="roadmap-content">
        <span className="dot"></span>
        <h4 className="title !tw-text-[18px] !tw-text-[#04A58A]">
          {props.item.title}
        </h4>
        <ul className="roadmap-list-style">{props.item.info}</ul>
      </div>
    </div>
  );
};

export default RoadmapTwoItem;
