/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export const useAllTransaction = () => {
  const [allTransaction, setAllTransaction] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [extraToken, setExtraToken] = useState();
  const GetAllTransaction = async () => {
    let extraTokens = [];
    const q = query(collection(db, "transaction"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      extraTokens.push(doc.data());
    });
    setAllTransaction(extraTokens);
  };
  const GetTotalAmount = async () => {
    const total = allTransaction.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.amountOfToken);
    }, 0);
    setTotalAmount(total.toFixed(2));
  };

  const getExtraToken = async () => {
    let extraTokens = [];
    const q = query(collection(db, "AddExtraToken"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      extraTokens.push(doc.data());
    });
    setExtraToken(extraTokens);
  };
  return {
    totalAmount,
    extraToken,
    allTransaction,
    GetAllTransaction,
    GetTotalAmount,
    getExtraToken,
  };
};
