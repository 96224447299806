import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import PdfLink from "../../assets/images/audit/audit.pdf";
const DocumentDropdown = () => {
  return (
    <div className="document-dropdown">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Document
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            target="_blank"
            href="https://docsend.com/view/2kf4zukhs5fb59bh"
          >
            Whitepaper
          </Dropdown.Item>
          <Dropdown.Item href={PdfLink} target="_blank">
            Audit Report
          </Dropdown.Item>
          {/* <Dropdown.Item href="/kyc">KYC Certificate</Dropdown.Item> */}
          <Dropdown.Item href="#how-to-buy">How To Buy</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DocumentDropdown;
