import React from "react";
import SmartDexImgMobile from "../../assets/smartDex/BOTiFiSmartDEX21.png";
import SmartDexImgDesktop from "../../assets/smartDex/SmartInvest.png";
const SmartDex = () => {
  return (
    <section id="smart-dex" className="tw-pt-6">
      <div className="container">
        <a href="https://presale.botifi.ai" target="_blank" rel="noreferrer">
          <img
            src={SmartDexImgDesktop}
            className="tw-w-full d-md-block d-none"
            alt="Smart-desktop"
          />
          <img
            src={SmartDexImgMobile}
            className="tw-w-full d-block d-md-none"
            alt="Smart-mobile"
          />
        </a>
      </div>
    </section>
  );
};

export default SmartDex;
