/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import img01 from "../../assets/images/banners/ComingSoon.png";
import Video from "../../assets/video/xtune (1).mp4";
import { Link } from "react-router-dom";
const WhoWeAre = (props) => {
  return (
    <section
      id="about"
      className="about-area  !tw-pt-[110px] sm:pt-130 !tw-pb-[100px] !tw-sm:pb-130"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <video
                className="tw-w-full"
                autoPlay
                muted
                loop
                playsInline
                controls={false}
              >
                <source src={Video} type="video/mp4" />
              </video>

              {/* <img src={img01} className="tw-w-full" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title !tw-text-[28px]">
                  The World's <span>First Smart DEX:</span> <br /> Where Trading
                  Does Itself.
                </h2>
              </div>
              <p>
                Beyond Ordinary DEX: Welcome to Smart Trading. Join the Future
                of Profitable, Loss-Free, and Fully Automated Crypto Trading.
              </p>
              <Link
                to="https://presale.botifi.ai/"
                target="_blank"
                className="btn"
              >
                Purchase Tokens
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
