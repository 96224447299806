import React from "react";
import Banner from "../../components/Banner/Banner";
// import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import Faq from "../../components/Faq/Faq";
import Video from "../../assets/video/DEV.mp4";
import ProfitableTrading from "../../components/profitableTrading/ProfitableTrading";
import ProjectDetails from "../../components/projectDetailsBox/ProjectDetails";
import SocialMediaLink from "../../components/socialMedia/SocialMediaLink";
import SmartDex from "../../components/smartDex/SmartDex";
import WhyBotifi from "../../components/whyBotifi/WhyBotifi";
import HowToBuy from "../../components/howtobuy/HowToBuy";

const Home = () => {
  return (
    <LayoutOne>
      <SocialMediaLink />
      <main className="fix">
        <div
          class="elfsight-app-2e2abcc1-575a-43af-b16b-e28c5a97227b"
          data-elfsight-app-lazy
        ></div>
        <Banner />
        <WhoWeAre />
        <TopPartners />
        <ProjectDetails />
        <WhyChooseUs />
        <SmartDex />
        <Sales />
        <ProfitableTrading />
        <div className=" tw-bg-[#111112]">
          {/* <Roadmap /> */}
          <RoadmapTwo />
          <WhyBotifi />
          <WhitePaper />
        </div>
        <TeamOne />
        <div className="tw-max-w-[1920px] tw-my-6 tw-mx-auto">
          <video className="tw-w-full" autoPlay muted loop controls={false}>
            <source src={Video} type="video/mp4" />
          </video>
        </div>

        <HowToBuy />
        <Faq />
        {/* <ContactOne /> */}
      </main>
    </LayoutOne>
  );
};

export default Home;
