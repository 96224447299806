import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import WOW from "wow.js";
// import HomeTwo from "./pages/Home/HomeTwo";
// import Blog from "./pages/Blog/Blog";
// import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import KycPage from "./components/kyc/KycPage";
import MPop from "./assets/images/banner/Mpop(2).png";
import DPop from "./assets/images/banner/Dpop(2).png";

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [isBanner, setIsBanner] = useState(true);
  return (
    <>
      {/* {isBanner && (
        <div className="tw-fixed tw-flex justify-content-center align-items-center tw-z-[100000] tw-top-0 tw-left-0 tw-h-screen tw-w-full">
          <img
            onClick={() => setIsBanner(false)}
            src={DPop}
            className="tw-w-[60%] tw-cursor-pointer tw-hidden md:tw-block"
            alt="banner"
          />

          <img
            onClick={() => setIsBanner(false)}
            src={MPop}
            className="tw-w-[80%] tw-mt-[140px] tw-cursor-pointer md:tw-hidden tw-block"
            alt="banner"
          />
        </div>
      )} */}

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/kyc" element={<KycPage />} /> */}
        {/* <Route path="home-two" element={<HomeTwo />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetailsPage />} /> */}
      </Routes>
    </>
  );
}

export default App;
