import React from "react";
import SalesHeading from "./SalesHeading";
import copy from "copy-to-clipboard";
import { CopyIcon } from "../Icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TokenDetails = () => {
  let walletAddress = "0XB1BF223D00A0C0086EE0AE6C8B1FCB1C2E4A479C";
  let firstThree = walletAddress.substring(0, 4);
  let lastThree = walletAddress.substring(walletAddress.length - 4);
  let result = `${firstThree}...${lastThree}`;
  const copyFunc = () => {
    copy("0XB1BF223D00A0C0086EE0AE6C8B1FCB1C2E4A479C");
    toast("Copied Successfully");
  };
  return (
    <section className="px-2">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        className={"taost-design"}
        pauseOnHover
      />
      <div className="container">
        <SalesHeading title=" botifi token details" />
        <div className="tw-flex space-y-4 tw-max-w-[1000px] tw-mx-auto tw-flex-col">
          <div className="tw-flex justify-content-center">
            <span className="tw-flex border p-2 tw-w-[200px] tw-text-[22px] tw-text-[#676767db]">
              Token Name
            </span>
            <span className="tw-flex border px-3 py-2 tw-w-[200px] tw-text-[22px] tw-text-[#f8f8f8]">
              BOTIFI.AI
            </span>
          </div>

          <div className="tw-flex justify-content-center">
            <span className="tw-flex border p-2 tw-w-[200px] tw-text-[22px] tw-text-[#676767db]">
              Token Name
            </span>
            <span className="tw-flex border px-3 py-2 tw-w-[200px] tw-text-[22px] tw-text-[#f8f8f8]">
              BOTIFI
            </span>
          </div>
          <div className="tw-flex justify-content-center">
            <span className="tw-flex border p-2 tw-w-[200px] tw-text-[22px] tw-text-[#676767db]">
              Chain
            </span>
            <span className="tw-flex border px-3 py-2 tw-w-[200px] tw-text-[22px] tw-text-[#f8f8f8]">
              BEP20
            </span>
          </div>
          <div className="tw-flex justify-content-center">
            <span className="tw-flex border p-2 tw-w-[200px] tw-text-[22px] tw-text-[#676767db]">
              Decimal
            </span>
            <span className="tw-flex border px-3 py-2 tw-w-[200px] tw-text-[22px] tw-text-[#f8f8f8]">
              18
            </span>
          </div>
          <div className="tw-flex justify-content-center">
            <span className="tw-flex border p-2 tw-w-[200px] tw-text-[22px] tw-text-[#676767db]">
              Max Supply
            </span>
            <span className="tw-flex border px-3 py-2 tw-w-[200px] tw-text-[22px] tw-text-[#f8f8f8]">
              100 Million
            </span>
          </div>
        </div>
        <div className="tw-flex mt-5  tw-items-center tw-flex-col justify-content-center">
          <span className="tw-text-[22px] tw-font-bold">
            TOKEN CONTRACT ADDRESS
          </span>
          <div className="tw-flex my-1 tw-cursor-pointer tw-space-x-3 tw-items-center">
            {" "}
            <span className="tw-text-[18px]  tw-text-white tw-font-normal">
              {result}
            </span>
            <button onClick={copyFunc} className="">
              <CopyIcon />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenDetails;
