/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { BatchBuyIcon, SmartAutoIcon, SmartSignalIcon } from "../Icons";
import MobileImage from "../../assets/images/folder2/3.png";
import AutoSmartTrading from "../../assets/images/projectDetails/AutoSmartTrading.png";
import CrossChain from "../../assets/images/projectDetails/Crosschain.png";
import BatchTrading from "../../assets/images/projectDetails/BatchTrading.png";
import Staking from "../../assets/images/projectDetails/Staking.png";
import SmartSignals from "../../assets/images/projectDetails/SmartSignals.png";
import SmartDashboard from "../../assets/images/projectDetails/SmartDashboard.png";
const ProjectDetails = () => {
  const data = [
    {
      title: "Cross-Chain Trading",
      icon: CrossChain,
      content: (
        <>
          <p>
            Seamlessly exchange assets across different blockchain networks for
            expanded opportunities and flexibility.
          </p>
        </>
      ),
    },
    {
      title: "Batch Buy & Sell",
      icon: BatchTrading,
      content: (
        <>
          <p>
            Trade multiple assets for one and save up to 60% on gas fees and 80%
            on trading time with smart trading.
          </p>
        </>
      ),
    },
    {
      title: "Staking",
      icon: Staking,
      content: (
        <>
          <p>
            Unlock the power of your crypto with hassle-free staking. Earn
            rewards while you HODL – it's that easy!
          </p>
        </>
      ),
    },

    {
      title: "Smart Auto Trading",
      icon: AutoSmartTrading,
      content: (
        <>
          <p>
            Explore the world of Smart Automatic Trading for effortless endless
            profits.
          </p>
        </>
      ),
    },

    {
      title: "Smart Trading Signals",
      icon: SmartSignals,
      content: (
        <>
          <p>
            Elevate your trading game with Smart Trading Signals in blockchain.
            Get real-time insights and make informed decisions to maximize your
            profits.
          </p>
        </>
      ),
    },

    {
      title: "Smart Analytics Dashboard",
      icon: SmartDashboard,
      content: (
        <>
          <p>
            Navigate the blockchain with precision using our Smart Analytics
            Dashboard. Gain actionable insights and stay ahead of the crypto
            curve.
          </p>
        </>
      ),
    },
  ];
  return (
    <section
      id="product"
      className="choose-area !tw-px-[15px] sm:pt-5 !tw-pb-[100px] sm:pb-130"
    >
      <div className="max-w-[1500px] mx-auto">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              {/* <span className="sub-title">why Choose us </span> */}
              <h2 className="title">
                SMART, FAST & PROFITABLE DEX
                <span> Smart DEX, Smart Profits</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="tw-relative">
          <div className="lg:tw-w-[80%] tw-w-full tw-relative tw-z-[2]">
            <div className="row justify-content-center choose-active">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="col-md-6 col-sm-10 mb-4 col-12 col-lg-4"
                >
                  <div className="choose-item !tw-bg-[#111112] !tw-min-h-[130px] !tw-space-x-3 !tw-flex h-100 !tw-p-4">
                    <div className="choose-icon !tw-min-w-[60px] !tw-h-[60px] !tw-text-center">
                      <img src={item.icon} />
                    </div>
                    <div className="choose-content">
                      <h2 className="title !tw-mb-3 !tw-text-[18px]">
                        {" "}
                        {item.title}{" "}
                      </h2>
                      <div className="tw-flex  !tw-text-[12px] tw-flex-col tw-space-y-4">
                        {item.content}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tw-w-[40%] tw-z-[1] lg:tw-block tw-hidden tw-pointer-events-none tw-absolute tw-bottom-[-30%] tw-h-[130%] tw-right-0">
            <img src={MobileImage} className="tw-h-full tw-object-cover" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;
