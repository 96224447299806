import React, { useState } from "react";
import MobileTokenDetails from "../../assets/mobileAssets/2.png";
import TokenDetailsImg from "../../assets/tokenomics/tokenDetails.png";
import TokenAllocationImage from "../../assets/tokenomics/tokenAllocation.png";
import VestingToken from "../../assets/tokenomics/vesting.png";
import botifiImage from "../../assets/tokenomics/BT21GTransparent55.png";

import MobileVestingToken from "../../assets/mobileAssets/3.png";
import MobileTokenAllocationImage from "../../assets/mobileAssets/4.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import TokenVestingTable from "./TokenVestingTable";
import TokenDetails from "./TokenDetails";
import TokenAllocation from "./TokenAllocation";

const Sales = () => {
  const [showTabs, setShowTabs] = useState("DETAILS");
  return (
    <>
      <section id="sales" className="chart-area chart-bg ">
        <div className="container ">
          <div className="!tw-py-[100px] sm:!tw-py-[130px]">
            <div className="tw-flex tw-flex-col   justify-content-center">
              <div className="tw-overflow-auto">
                <div className="tw-flex mb-5 tw-border-[#04a58a] tw-border-[2px]   tw-rounded-lg my-4 !tw-w-[600px] tw-mx-auto">
                  <button
                    onClick={() => setShowTabs("DETAILS")}
                    className={`tw-grow py-2 ${
                      showTabs === "DETAILS" ? "tw-bg-[#04a58a] text-white" : ""
                    }`}
                  >
                    TOKEN DETAILS
                  </button>
                  <button
                    onClick={() => setShowTabs("VESTING")}
                    className={`tw-grow py-2 ${
                      showTabs === "VESTING" ? "tw-bg-[#04a58a] text-white" : ""
                    }`}
                  >
                    TOKEN VESTING
                  </button>
                  <button
                    onClick={() => setShowTabs("ALLOCATION")}
                    className={`tw-grow py-2 ${
                      showTabs === "ALLOCATION"
                        ? "tw-bg-[#04a58a] text-white"
                        : ""
                    }`}
                  >
                    TOKEN ALLOCATION
                  </button>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                {showTabs === "DETAILS" && <TokenDetails />}

                {showTabs === "VESTING" && <TokenVestingTable />}
                {showTabs === "ALLOCATION" && <TokenAllocation />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sales;
