import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import DocumentDropdown from "./DocumentDropdown";
import PdfLink from "../../assets/images/audit/audit.pdf";
const HeaderOne = () => {
  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler"}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img
                        src={logoImage}
                        className="tw-w-[200px] lg:tw-w-[130px] xl:tw-w-[200px]"
                        alt="BigTech Logo"
                      />
                    </Link>
                  </div>

                  <div
                    className={cn(
                      "navbar-wrap tw-grow main-menu d-none d-lg-flex"
                    )}
                  >
                    <ul
                      className={
                        "navigation !tw-w-full xl:!tw-justify-center !tw-justify-between !tw-ml-0 grow tw-px-6"
                      }
                    >
                      <li
                        className={`!tw-mr-0 xl:!tw-px-4 ${cn(
                          hash == "#about-us" && "active"
                        )}`}
                      >
                        <Link
                          to="#about-us"
                          className={"section-link"}
                          onClick={() => handleClickScroll("about-us")}
                        >
                          About us
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 xl:!tw-px-4 ${isActiveLink(
                          "#product"
                        )}`}
                      >
                        <Link
                          to="#product"
                          className={"section-link"}
                          onClick={() => handleClickScroll("product")}
                        >
                          Products
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 xl:!tw-px-4 ${isActiveLink(
                          "#sales"
                        )}`}
                      >
                        <Link
                          to="#sales"
                          className={"section-link"}
                          onClick={() => handleClickScroll("sales")}
                        >
                          Tokenomics
                        </Link>
                      </li>

                      <li
                        className={`!tw-mr-0 xl:!tw-px-4 ${isActiveLink(
                          "#roadmap"
                        )}`}
                      >
                        <Link
                          to="#roadmap"
                          className={"section-link"}
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          Roadmap
                        </Link>
                      </li>

                      <li
                        className={`!tw-mr-0 xl:!tw-px-4 ${isActiveLink(
                          "#faq"
                        )}`}
                      >
                        <Link
                          to="#faq"
                          className={"section-link"}
                          onClick={() => handleClickScroll("faq")}
                        >
                          FAQ
                        </Link>
                      </li>
                      <li className={`!tw-mr-0 xl:!tw-px-4 `}>
                        <Link
                          to="https://presale.botifi.ai/transaction"
                          className={"section-link"}
                          target="_blank"
                          onClick={() => handleClickScroll("faq")}
                        >
                          VERIFY TXN
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 d-lg-none d-block xl:!tw-px-4 ${isActiveLink(
                          "#whitepaper"
                        )}`}
                      >
                        <Link
                          to="https://docsend.com/view/2kf4zukhs5fb59bh"
                          target="_blank"
                          className={"section-link"}
                        >
                          Whitepaper
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 d-lg-none d-block xl:!tw-px-4 ${isActiveLink(
                          "#whitepaper"
                        )}`}
                      >
                        <Link
                          to={PdfLink}
                          target="_blank"
                          className={"section-link"}
                        >
                          Audit Report
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 d-lg-none d-block xl:!tw-px-4 ${isActiveLink(
                          "#whitepaper"
                        )}`}
                      >
                        <Link
                          to="https://docsend.com/view/2kf4zukhs5fb59bh"
                          target="_blank"
                          className={"section-link"}
                        >
                          KYC Certificate
                        </Link>
                      </li>
                      <li
                        className={`!tw-mr-0 d-lg-none d-block xl:!tw-px-4 ${isActiveLink(
                          "#whitepaper"
                        )}`}
                      >
                        <a
                          href="#how-to-buy"
                          target=""
                          className={"section-link"}
                        >
                          How To Buy
                        </a>
                      </li>
                      <li
                        className={`!tw-mr-0 d-none d-lg-block xl:!tw-px-4 ${isActiveLink(
                          "#whitepaper"
                        )}`}
                      >
                        <DocumentDropdown />
                      </li>
                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul className="!tw-ml-0">
                      <li className={"header-btn"}>
                        <Link
                          to="https://presale.botifi.ai/"
                          target="_blank"
                          className="btn  lg:!tw-px-6"
                        >
                          Buy Botifi
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="BigTech Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                  </div>

                  <div className={"social-links"}>
                    <ul className="clearfix">
                      <li>
                        <a
                          href="https://www.youtube.com/@BOTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/BoTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/BoTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.reddit.com/r/Botifi_Ai/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-reddit"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://t.me/BOTiFi_Ai"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
