export const CrossChainIcon = () => (
  <svg
    aria-hidden="true"
    class="fa-fw e-font-icon-svg e-fab-codepen"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path d="M502.285 159.704l-234-156c-7.987-4.915-16.511-4.96-24.571 0l-234 156C3.714 163.703 0 170.847 0 177.989v155.999c0 7.143 3.714 14.286 9.715 18.286l234 156.022c7.987 4.915 16.511 4.96 24.571 0l234-156.022c6-3.999 9.715-11.143 9.715-18.286V177.989c-.001-7.142-3.715-14.286-9.716-18.285zM278 63.131l172.286 114.858-76.857 51.429L278 165.703V63.131zm-44 0v102.572l-95.429 63.715-76.857-51.429L234 63.131zM44 219.132l55.143 36.857L44 292.846v-73.714zm190 229.715L61.714 333.989l76.857-51.429L234 346.275v102.572zm22-140.858l-77.715-52 77.715-52 77.715 52-77.715 52zm22 140.858V346.275l95.429-63.715 76.857 51.429L278 448.847zm190-156.001l-55.143-36.857L468 219.132v73.714z"></path>
  </svg>
);

export const BatchBuyIcon = () => (
  <svg
    aria-hidden="true"
    class="fa-fw e-font-icon-svg e-fas-chess-board"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path d="M255.9.2h-64v64h64zM0 64.17v64h64v-64zM128 .2H64v64h64zm64 255.9v64h64v-64zM0 192.12v64h64v-64zM383.85.2h-64v64h64zm128 0h-64v64h64zM128 256.1H64v64h64zM511.8 448v-64h-64v64zm0-128v-64h-64v64zM383.85 512h64v-64h-64zm128-319.88v-64h-64v64zM128 512h64v-64h-64zM0 512h64v-64H0zm255.9 0h64v-64h-64zM0 320.07v64h64v-64zm319.88-191.92v-64h-64v64zm-64 128h64v-64h-64zm-64 128v64h64v-64zm128-64h64v-64h-64zm0-127.95h64v-64h-64zm0 191.93v64h64v-64zM64 384.05v64h64v-64zm128-255.9v-64h-64v64zm191.92 255.9h64v-64h-64zm-128-191.93v-64h-64v64zm128-127.95v64h64v-64zm-128 255.9v64h64v-64zm-64-127.95H128v64h64zm191.92 64h64v-64h-64zM128 128.15H64v64h64zm0 191.92v64h64v-64z"></path>
  </svg>
);

export const SmartAutoIcon = () => (
  <svg
    aria-hidden="true"
    class="fa-fw e-font-icon-svg e-fas-robot"
    viewBox="0 0 640 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path>
  </svg>
);

export const SmartSignalIcon = () => (
  <svg
    aria-hidden="true"
    class="fa-fw e-font-icon-svg e-far-chart-bar"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"></path>
  </svg>
);
export const TickIcon = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1805_3536)">
      <rect width="46" height="46" rx="23" fill="#04A58A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 23C0 16.9 2.42321 11.0499 6.73654 6.73654C11.0499 2.42321 16.9 0 23 0C29.1 0 34.9501 2.42321 39.2635 6.73654C43.5768 11.0499 46 16.9 46 23C46 29.1 43.5768 34.9501 39.2635 39.2635C34.9501 43.5768 29.1 46 23 46C16.9 46 11.0499 43.5768 6.73654 39.2635C2.42321 34.9501 0 29.1 0 23ZM21.6875 32.844L34.9293 16.2901L32.5373 14.3765L21.2459 28.4863L13.248 21.8224L11.2853 24.1776L21.6875 32.8471V32.844Z"
        fill="#111112"
      />
    </g>
    <defs>
      <clipPath id="clip0_1805_3536">
        <rect width="46" height="46" rx="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GreenTick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0 0 48 48"
  >
    <linearGradient
      id="TpFkpHq7AGWb~Tkla4kyga_YDFV6MVN9cNl_gr1"
      x1="24"
      x2="24"
      y1="6.121"
      y2="42.039"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#9dffce"></stop>
      <stop offset="1" stop-color="#50d18d"></stop>
    </linearGradient>
    <path
      fill="url(#TpFkpHq7AGWb~Tkla4kyga_YDFV6MVN9cNl_gr1)"
      d="M40,42H8c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2h32c1.1,0,2,0.9,2,2v32C42,41.1,41.1,42,40,42z"
    ></path>
    <linearGradient
      id="TpFkpHq7AGWb~Tkla4kygb_YDFV6MVN9cNl_gr2"
      x1="13"
      x2="36"
      y1="24.793"
      y2="24.793"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".824" stop-color="#135d36"></stop>
      <stop offset=".931" stop-color="#125933"></stop>
      <stop offset="1" stop-color="#11522f"></stop>
    </linearGradient>
    <path
      fill="url(#TpFkpHq7AGWb~Tkla4kygb_YDFV6MVN9cNl_gr2)"
      d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414	c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414	c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z"
    ></path>
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1562 24.375H6.90625C6.58302 24.375 6.27302 24.2466 6.04446 24.018C5.8159 23.7895 5.6875 23.4795 5.6875 23.1562V6.5C5.6875 6.28451 5.7731 6.07785 5.92548 5.92548C6.07785 5.7731 6.28451 5.6875 6.5 5.6875H23.1562C23.4795 5.6875 23.7895 5.8159 24.018 6.04446C24.2466 6.27302 24.375 6.58302 24.375 6.90625V23.1562C24.375 23.4795 24.2466 23.7895 24.018 24.018C23.7895 24.2466 23.4795 24.375 23.1562 24.375Z"
      fill="#EBEBEB"
    />
    <path
      d="M5.6875 4.0625H20.3125V2.84375C20.3125 2.52052 20.1841 2.21052 19.9555 1.98196C19.727 1.7534 19.417 1.625 19.0938 1.625H3.04688C2.66977 1.625 2.30811 1.7748 2.04146 2.04146C1.7748 2.30811 1.625 2.66977 1.625 3.04688V19.0938C1.625 19.417 1.7534 19.727 1.98196 19.9555C2.21052 20.1841 2.52052 20.3125 2.84375 20.3125H4.0625V5.6875C4.0625 5.25652 4.2337 4.8432 4.53845 4.53845C4.8432 4.2337 5.25652 4.0625 5.6875 4.0625Z"
      fill="#EBEBEB"
    />
  </svg>
);
