import React from "react";
import img01 from "../../assets/images/partners/1.png";
import img02 from "../../assets/images/partners/2.png";
import img03 from "../../assets/images/partners/3.png";
import img04 from "../../assets/images/partners/4.png";
import img05 from "../../assets/images/partners/5.png";
import img06 from "../../assets/images/partners/6.png";
import img07 from "../../assets/images/partners/7.png";
import img08 from "../../assets/images/partners/8.png";
import img09 from "../../assets/images/partners/9.png";
import img10 from "../../assets/images/partners/10.png";
import img11 from "../../assets/images/partners/11.png";
import img12 from "../../assets/images/partners/12.png";
import img13 from "../../assets/images/partners/13.png";
import img14 from "../../assets/images/partners/14.png";
import img15 from "../../assets/images/partners/15.png";

import img16 from "../../assets/images/partners/16.png";
import img17 from "../../assets/images/partners/17.png";
import img18 from "../../assets/images/partners/18.png";
import img19 from "../../assets/images/partners/19.png";
import img20 from "../../assets/images/partners/10.png";
import AsFeatured from "../../assets/images/featured/pressRelease.png";
import AsFeaturedMobile from "../../assets/media/Mobile post.png";
import SlickSlider from "../SlickSlider/SlickSlider";
import Video from "../../assets/video/567T2Z (1).mp4";
import DesktopSmartFast from "../../assets/media/BOT2882.png";
import MobileVideo from "../../assets/video/MobileVideo.mp4";

const TopPartners = () => {
  const partners_list = [
    {
      src: img01,
      title: "",
    },
    {
      src: img02,
      title: "",
    },
    {
      src: img03,
      title: "",
    },
    {
      src: img04,
      title: "",
    },
    {
      src: img05,
      title: "",
    },
    {
      src: img06,
      title: "",
    },
    {
      src: img07,
      title: "",
    },
    {
      src: img08,
      title: "",
    },
    {
      src: img09,
      title: "",
    },
    {
      src: img10,
      title: "",
    },
    {
      src: img11,
      title: "",
    },
    {
      src: img12,
      title: "",
    },
    {
      src: img13,
      title: "",
    },
    {
      src: img14,
      title: "",
    },
    {
      src: img15,
      title: "",
    },

    {
      src: img16,
      title: "",
    },
    {
      src: img17,
      title: "",
    },
    {
      src: img18,
      title: "",
    },
    {
      src: img19,
      title: "",
    },
    {
      src: img20,
      title: "",
    },
  ];
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
  };
  return (
    <div className="partner-area !tw-pb-[100px] sm:pb-130">
      <div className="tw-max-w-[1920px] tw-mx-auto">
        <div className="row justify-content-center">
          <div class="col-xl-6">
            <div class="section-title text-center mb-70">
              {/* <span class="sub-title">OUr team</span> */}
              <h2 class="title">Top Partners</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="partner-wrap">
              <ul className="row choose-active">
                <SlickSlider settings={slickSettings}>
                  {partners_list.map((item, index) => (
                    <li
                      className="!tw-w-[230px] tw-py-4 tw-mx-3 !tw-flex tw-justify-center "
                      key={index}
                    >
                      <img
                        className="!tw-opacity-1"
                        src={item.src}
                        alt={item.title}
                      />
                    </li>
                  ))}
                </SlickSlider>
              </ul>
            </div>
          </div>
        </div>
        <div className="tw-w-full tw-mt-8">
          <img
            src={AsFeatured}
            alt=""
            className="tw-w-full sm:tw-flex tw-hidden"
          />
          <img
            src={AsFeaturedMobile}
            alt=""
            className="tw-tw-w-full flex sm:tw-hidden"
          />
        </div>
        <div
          id="about-us"
          className="!tw-pb-0 sm:tw-py-12 tw-max-w-[1440px] tw-mx-auto tw-mt-8"
        >
          <img
            src={DesktopSmartFast}
            className="tw-w-full md:tw-block tw-hidden"
            alt=""
          />
          <video
            className="tw-w-full tw-block md:tw-hidden"
            autoPlay
            muted
            loop
            playsInline
            controls={false}
          >
            <source src={MobileVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default TopPartners;
