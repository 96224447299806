/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import shape01 from "../../assets/img/images/faq_shape01.png";
import shape02 from "../../assets/img/images/faq_shape02.png";
import shape03 from "../../assets/img/images/faq_shape03.png";
import FaqItem from "./FaqItem";

const Faq = () => {
  const faq_items = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: "What is Botifi Smart DEX?",
      details: `BOTiFi Smart DEX is the first-ever fully automated, emotion-free AI powered decentralized trading platform that automates the entire crypto trading process for you. BOTiFi smart bots works 24/7 under all market conditions for  maximum profits.`,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Why should I switch to BOTiFi?",
      details: `BOTiFi Smart DEX aim to offer a fully automated, highly efficient, and emotionless decentralized trading that works under all market conditions 24/7 to generate profits by employing a range of advanced algorithms and strategies.`,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "What sets BOTiFi apart from other DEXs?",
      details: `Botifi Smart Dex trading has several advantages over traditional DEXs. These advantages stem from the capabilities and the features that advanced AI-driven bots can offer, providing traders with a competitive edge and improved efficiency, automated 24/7 trading, human emotion-free, lightening fast analysis and order execution and works under all market conditions.`,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Can I access BOTiFi even without having the technical knowledge?",
      details: `BOTiFi offers zero knowledge Smart Bots, and doesn't demand any technical expertise.  Users can  configured and get started within minutes, making it accessible to everyone while catering to all your sophisticated trading requirements.`,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "How do I control & regulate my risk?",
      details: (
        <>
          When you trade on{" "}
          <a href="/" target="_blank">
            BOTiFi.Ai
          </a>
          , you retain full control over your assets & orders. Analyse the
          performance of each strategy, monitor each order and take action to
          manage your risk exposure. Start, pause or stop, add or remove funds
          at any time.
        </>
      ),
    },

    {
      id: "headingSix",
      controls: "collapseSix",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "How do I buy BOTIFI tokens?",
      details: (
        <>
          You can purchase $BOTIFI tokens in presale from the official website
          <a
            className="!tw-text-[#32328d]"
            href="https://presale.botifi.ai"
            target="_blank"
          >
            {" "}
            https://presale.botifi.ai{" "}
          </a>
          You can buy BOTIFI using ETH, USDT, or BNB, BUSD utilizing wallets
          like Metamask, Trustwallet, or any other decentralized wallet. Make
          sure to have adequate gas fees to facilitate your transaction.
        </>
      ),
    },

    {
      id: "headingEighth",
      controls: "collapseEighth",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "When will BOTIFI token expected to be listed on exchanges?",
      details: (
        <>
          The listing of the BOTIFI token is scheduled for the first quarter of
          2024, encompassing multiple tier-1 & tier-2 exchanges.
          {/* <a
            className=""
            target="_blank"
            href="http://presale.botifi.ai"
            rel="noreferrer"
          >
            Buy Now
          </a> */}
        </>
      ),
    },
  ];

  return (
    <section id="faq" className="tw-bg-[#111112] tw-pb-8">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center my-5">
              <h2 className="title  !tw-text-[#04A58A]">
                Frequently asked questions
              </h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
