/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import shape01 from "../../assets/images/heroImages/11.png";
import shape02 from "../../assets/images/heroImages/12.png";
import shape03 from "../../assets/images/heroImages/13.png";
import fireIcon from "../../assets/images/heroImages/4.png";
// import CountDownOne from "../CountDown/CountDownOne";
import Video from "../../assets/video/GoatHQ.mp4";
import { useAllTransaction } from "../hooks/useAllTransaction";
const Banner = (props) => {
  const {
    totalAmount,
    GetAllTransaction,
    GetTotalAmount,
    allTransaction,
    extraToken,
    getExtraToken,
  } = useAllTransaction();

  useEffect(() => {
    GetAllTransaction();
    getExtraToken();
  }, []);

  useEffect(() => {
    if (allTransaction && allTransaction.length > 0) {
      GetTotalAmount();
    }
  }, [allTransaction]);
  const [total, setTotal] = useState();
  useEffect(() => {
    if (totalAmount) {
      const extraTokenAmount = extraToken[0].token * 0.14;
      setTotal((extraTokenAmount + Number(totalAmount)).toFixed(2));
    }
  }, [totalAmount]);

  return (
    <section className="banner-area !tw-pt-[50px] tw-min-h-[calc(100vh-90px)] tw-relative banner-bg">
      <img
        src={shape02}
        alt=""
        className="tw-w-[160px] z-[3] tw-absolute tw-left-0 tw-bottom-[0]"
      />
      <video
        className="tw-w-full tw-z-[1] tw-opacity-[0.3] tw-h-full tw-absolute tw-top-0 tw-left-0 tw-object-cover"
        autoPlay
        muted
        loop
        playsInline
        controls={false}
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="banner-shape-wrap tw-z-[10] tw-relative">
        <img src={shape01} alt="" className="tw-w-[160px] img-one" />

        <img
          src={shape03}
          alt=""
          className="tw-w-[160px] !tw-right-[10%] !tw-top-[30%] img-three"
        />
      </div>

      <div className="container tw-relative tw-z-[10]">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <img
                className="tw-w-[80px] tw-mt-10 !tw-mb-0"
                src={fireIcon}
                alt=""
              />

              <h2 className="title tw-max-w-[800px] tw-mx-auto tw-text-[32px]">
                Join The Future Of Fully <span>Automatic, Profitable </span>
                Crypto Trading With 1st Ever AI Smart DEX.
                <br />
                <span className="c-article__title">
                  100% Profits, 0% Losses
                </span>
              </h2>
            </div>
            <div className="banner-progress-wrap">
              <ul className="!tw-m-0">
                <li>Raised</li>
                <li className="!tw-flex tw-items-center tw-justify-center !tw-flex-col">
                  <span>Pre-Listing </span>
                  <span> Live Now</span>
                </li>
                <li>Target</li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: (total / 4831575) * 100 + "%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <h4 className="title">
                ${total}
                <span>$4,831,575</span>
              </h4>
            </div>

            <div className="header-btn mx-auto tw-flex tw-justify-center">
              {" "}
              <a href="https://presale.botifi.ai" className="btn">
                Buy Now
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">ICO Will Start In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Banner;
