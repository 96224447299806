/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/images/whitepaper/Ethn2Xg577.png";

const WhitePaper = () => {
  return (
    <section className="document-area pt-0 sm:pt-60">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 order-2 order-lg-0">
            <div
              className="document-img text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <img className="" src={docImg} alt="" />
              {/* <video className="tw-w-full" autoPlay muted loop controls={false}>
                <source src={Video} type="video/mp4" />
              </video> */}
            </div>
          </div>

          <div className="col-lg-5 col-md-7">
            <div
              className="document-content mt-50 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">Whitepaper</span>
                <h2 className="title !tw-text-[28px]">
                  Step into a Smarter Future, Join the{" "}
                  <a href="/">
                    <span>BOTiFi.Ai</span>
                  </a>{" "}
                  Community!
                </h2>
              </div>

              <ul className="document-list">
                <a
                  target="_blank"
                  href="https://docsend.com/view/2kf4zukhs5fb59bh"
                  rel="noreferrer"
                >
                  <li className="!tw-text-blue-300 hover:!tw-text-blue-200">
                    Whitepaper
                  </li>
                </a>

                <a
                  target="_blank"
                  href="https://t.me/BOTiFi_Ai"
                  rel="noreferrer"
                >
                  <li className="!tw-text-blue-300 hover:!tw-text-blue-200">
                    Join Telegram
                  </li>
                </a>
                {/* <a
                  target="_blank"
                  href="https://twitter.com/BoTiFi_Ai"
                  rel="noreferrer"
                >
                  <li className="!tw-text-blue-300 hover:!tw-text-blue-200">
                    Follow us on Twitter
                  </li>
                </a> */}

                <a
                  target="_blank"
                  href="https://tawk.to/chat/65074163b1aaa13b7a775ae4/1hai5es2g"
                  rel="noreferrer"
                >
                  <li className="!tw-text-blue-300 hover:!tw-text-blue-200">
                    24/7 Live Assistance
                  </li>
                </a>

                <a
                  target="_blank"
                  href="https://presale.botifi.ai"
                  rel="noreferrer"
                >
                  <li className="!tw-text-blue-300 hover:!tw-text-blue-200">
                    Buy $BOTIFI Tokens
                  </li>
                </a>
              </ul>

              {/* <Link to="/" className="btn">
                Download Doc
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
