import React from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Q1-2023",
      title: "Phase-1(Completed)",
      info: (
        <>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Project Conceptualizations and Planning.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Refine the Concept.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Market Research.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Technical Feasibility.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Form the Core Team.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Define Project Scope & Objectives.
          </li>
        </>
      ),
    },
    {
      roadmapTitle: "Q2-2023",
      title: "Phase-2(Completed)",
      info: (
        <>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Legal and Regulatory Compliance.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Project's architecture & Technical Aspects.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Smart Contract Development.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Minimum Viable Product (MVP).
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Website & Official Whitepaper Development.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Team Expansion & Beta Testings.
          </li>
        </>
      ),
    },
    {
      roadmapTitle: "Q3-2023",
      title: "Phase-3",
      info: (
        <>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Community Building.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Events With Further Community Growth.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Presale Launch & Partnerships.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Aggressive Marketing Campaigns.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Partnerships and Integrations.
          </li>
        </>
      ),
    },
    {
      roadmapTitle: "Q4-Q1 2023-2024",
      title: "Phase-4(Live)",
      info: (
        <>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Platform Enhancements & Further Features Launch..
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            App Development & Upgrade.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Events and Community Growth.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Global Expansion & Launch of B2B Services.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Token Listing on Leading Exchanges.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            DApp Ecosystem & Further B2B Strategic Collaborations.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Exchange Listing.
          </li>
          <li className="tw-flex tw-items-center tw-text-[15px]">
            Smart Dex Mainnet Launch (Beta).
          </li>
        </>
      ),
    },
  ];

  return (
    <section
      id="roadmap"
      className="roadmap-area-two !tw-py-[100px] sm:pt-130 pb-100"
    >
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title section-title-two text-center tw-mb-4 sm:mb-65">
              <span className="sub-title">roadmap</span>
              <h2 className="title !tw-text-[#04A58A]">
                <span className="!tw-text-white">BOTiFi</span> Strategy{" "}
                <sapn className="!tw-text-white">and</sapn>{" "}
                <span className="!tw-text-white">Project</span> Roadmap
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;
