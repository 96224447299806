import React from "react";

const SocialMediaLink = () => {
  return (
    <div
      className={
        "social-links tw-fixed px-3 py-3 tw-right-2 tw-border tw-border-[#04a58a] tw-rounded-2xl tw-top-[50%] tw-z-[10000] tw-translate-y-[-50%]"
      }
    >
      <ul className="!tw-flex tw-space-y-3 tw-flex-col">
        <li>
          <a
            href="https://www.youtube.com/@BOTiFi_Ai"
            target="_blank"
            rel="noreferrer"
          >
            <i className="tw-text-[22px] hover:tw-text-white fab fa-youtube"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/BoTiFi_Ai"
            target="_blank"
            rel="noreferrer"
          >
            <i className="tw-text-[22px] hover:tw-text-white fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/BoTiFi_Ai"
            target="_blank"
            rel="noreferrer"
          >
            <i className="tw-text-[22px] hover:tw-text-white fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.reddit.com/r/Botifi_Ai/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="tw-text-[22px] hover:tw-text-white fab fa-reddit"></i>
          </a>
        </li>
        <li>
          <a href="https://t.me/BOTiFi_Ai" target="_blank" rel="noreferrer">
            <i className="tw-text-[22px] hover:tw-text-white fab fa-telegram"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaLink;
